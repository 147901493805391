
import { Options, Vue } from 'vue-class-component'
import { getSecurityManager } from '@/security/securityManager'

@Options({
})
export default class Logout extends Vue {
  async beforeMount () {
    await getSecurityManager().logout()
  }
}
